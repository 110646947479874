const doesSupportWebp = () => {
  const elem = document.createElement('canvas');

  if (!!(elem.getContext && elem.getContext('2d'))) {
   return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
  }
  return false; // very old browser like IE 8, canvas not supported
}

const fallbackForWebp = () => {
  if (doesSupportWebp()) { return }

  res = document.getElementsByClassName('webp')

  Array.from(res).forEach(element => {
    element.className += ' ' + 'no-webp'
  })
}

document.addEventListener('DOMContentLoaded', () => fallbackForWebp())
