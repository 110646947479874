document.addEventListener("DOMContentLoaded",() => {
  const seeMoreContents = document.querySelectorAll('.see-more-content');

  seeMoreContents.forEach(content => {
    content.addEventListener('click', function() {
      content.classList.toggle('is-open');
    });
  })

});
