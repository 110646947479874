function initToggleContent() {
  console.log("Initializing toggle content");
  const toggleContents = document.querySelectorAll(".toggle-content");

  toggleContents.forEach((content) => {
    const buttons = content.querySelectorAll(".toggle-button");
    const detailInfo = content.querySelector(".detail-info");
    const allElms = new Set([...buttons, detailInfo]);

    const change = () => {
      allElms.forEach((el) => {
        el.classList.toggle("is-hidden");
      });
    };
    buttons.forEach((button) => button.addEventListener("click", change));
  });
}

// DOMContentLoadedを使っていたが、深い階層のパーシャルでtoggleを使っているケースでうまく稼働しなかった
// DOMを構築する際に、深い階層のパーシャルがまだ処理されていないからだと思わえる
// パフォーマンスが悪化する恐れがあるが、コードルールの簡潔さを優先して一旦このままにする
window.addEventListener("load", initToggleContent);


// .toggle-content
//   .toggle-button もっと見る
//   .detail-info.is-hidden
//     普段隠れている情報
//     toggle-button 隠す
